import React from "react";
import { HtmlWorkout } from "./forms/htmlWorkout";
//import { testData } from "./models/htmlDocumentFactory";

export const App = () => {

    return (
        <HtmlWorkout document={undefined} />
    );
};
